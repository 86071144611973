import React from "react";
import { Grid } from "semantic-ui-react";
import Helmet from "react-helmet";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  Contact,
  Menu
} from "@fishrmn/fishrmn-components";
import FullMenu from "../files/Full Menu Thai Amarin.pdf";
import LunchMenu from "../files/Lunch Specials Thai Amarin.pdf";
import getFormattedMenu from "../utils/format-menu";
import MessageBanner from "../components/MessageBanner/MessageBanner";

import Layout from "../components/Layout";

export default class extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <MessageBanner />
        <Layout>
          <Helmet>
            <title>Thai Amarin | Dinner Menu</title>
            <meta name="description" content="" />
          </Helmet>
          <PageLayout
            hero={
              <HeroElement
                header={"Dinner Menu"}
                tagline={""}
                images={[
                  "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/IMG_9720.JPG"
                ]}
                showMap={false}
                ctas={[
                  { text: "Download Menu", link: FullMenu, internal: false }
                ]}
                ctaColor={"white"}
                ctaInverted={true}
                containerColor={"primary"}
                textColor={"white"}
                showMultiple={true}
                overlay={true}
                fullWidth={true}
                parallax={true}
                containerAs={"solid"}
                height={"100vh"}
                gutter={false}
              />
            }
          >
            <Grid stackable className="component-section-container">
              <Grid.Column width={16} className="food-menu">
                <Menu
                  menuItems={getFormattedMenu("Dinner Menu")}
                  itemsPerRow={2}
                  itemAs={"Item"}
                  centerHeaders={false}
                />
              </Grid.Column>
            </Grid>
          </PageLayout>
        </Layout>
      </React.Fragment>
    );
  }
}
